import _unistUtilStringifyPosition from "unist-util-stringify-position";
var exports = {};
var stringify = _unistUtilStringifyPosition;
exports = VMessage; // Inherit from `Error#`.

function VMessagePrototype() {}

VMessagePrototype.prototype = Error.prototype;
VMessage.prototype = new VMessagePrototype(); // Message properties.

var proto = VMessage.prototype;
proto.file = "";
proto.name = "";
proto.reason = "";
proto.message = "";
proto.stack = "";
proto.fatal = null;
proto.column = null;
proto.line = null; // Construct a new VMessage.
//
// Note: We cannot invoke `Error` on the created context, as that adds readonly
// `line` and `column` attributes on Safari 9, thus throwing and failing the
// data.

function VMessage(reason, position, origin) {
  var parts;
  var range;
  var location;

  if (typeof position === "string") {
    origin = position;
    position = null;
  }

  parts = parseOrigin(origin);
  range = stringify(position) || "1:1";
  location = {
    start: {
      line: null,
      column: null
    },
    end: {
      line: null,
      column: null
    }
  }; // Node.

  if (position && position.position) {
    position = position.position;
  }

  if (position) {
    // Position.
    if (position.start) {
      location = position;
      position = position.start;
    } else {
      // Point.
      location.start = position;
    }
  }

  if (reason.stack) {
    this.stack = reason.stack;
    reason = reason.message;
  }

  this.message = reason;
  this.name = range;
  this.reason = reason;
  this.line = position ? position.line : null;
  this.column = position ? position.column : null;
  this.location = location;
  this.source = parts[0];
  this.ruleId = parts[1];
}

function parseOrigin(origin) {
  var result = [null, null];
  var index;

  if (typeof origin === "string") {
    index = origin.indexOf(":");

    if (index === -1) {
      result[1] = origin;
    } else {
      result[0] = origin.slice(0, index);
      result[1] = origin.slice(index + 1);
    }
  }

  return result;
}

export default exports;